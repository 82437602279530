<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row dashboard-page-wrapper">
      <div class="col-md-12">
        <div
          class="mb-4 d-flex justify-content-between align-items-center dashboard-heading-section"
        >
          <h3 class="mb-0 mr-3">Dashboard</h3>
          <div class="position-relative custom-select-box">
            <input
              type="text"
              onkeydown="return false"
              placeholder="Select Restaurant"
              :value="selectedRestaurant"
              class="form-control w-100"
              @click="SearchDropdown"
            />

            <div>
              <ul
                v-if="ShowDropdownList"
                class="DropdownList"
                @click="closeDropdown"
              >
                <li
                  v-for="res in restaurants"
                  :key="res.id"
                  :value="res.uuid"
                  @click="dashboardFilter(res.uuid, res.title)"
                >
                  <a href="javascript:void(0);" class="dropdownList-text">{{
                    res.title
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <!-- Chart Revenue Profit -->
          <div class="chart-wrapper">
            <chart
              :cards="data.cards"
              :line="data.users"
              :revenue_line="data.revenues"
              :total_revenue="data.revenue"
              :customers="customers"
              :all_customers="all_customers"
              :usedItems="usedItems"
              :favItems="favItems"
              :sales="sales"
              :best_sell_menus="best_sell_menus"
              statistics="Users Statistics"
              revenue="All Restaurants Revenue"
            />
          </div>
          <!-- recent Orders -->
          <div class="recent-order">
            <div>
              <!--              <RecentOrder />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RecentOrder from "./RecentOrders";
import chart from "./Charts/ApaxChart";
// import ApiService from "@/core/services/api.service";
import axios from "axios";
import helpers from "../../helpers";

export default {
  name: "dashboard",
  data() {
    return {
      restaurants: [],
      customers: [],
      all_customers: [],
      usedItems: [],
      favItems: [],
      best_sell_menus: [],
      sales: {},
      restaurant_uuid: "",
      loader: false,
      data: {
        analytics: {
          total_sale_today_count: 0,
          total_sale_today_amount: 0,
          total_sale_week_count: 0,
          total_sale_week_amount: 0,
          total_sale_month_count: 0,
          total_sale_month_amount: 0
        },
        cards: [],
        revenues: [],
        revenue: {
          total_revenue: 0
        },
        users: []
      },
      ShowDropdownList: false,
      uuid: "",
      value: 40,
      rating: 3,
      selectedRestaurant: "",
      x:
        "m15 481.496094h89.71875l178.734375 30.785156c3.824219.65625 7.871094-.160156 11.273437-2.589844.050782-.035156.101563-.066406.152344-.105468l211-155c6.601563-4.847657 8.09375-14.097657 " +
        "3.347656-20.777344-23.320312-32.820313-69.75-41.4375-103.5-19.203125l-53.480468 35.265625c-8.199219-11.132813-21.390625-18.371094-36.246094-18.371094h-48.679688c-8.28125 " +
        "0-11.660156-5.984375-16.953124-9.941406-39.546876-34.800782-99.1875-34.800782-138.734376 0l-11.292968 9.941406h-85.339844c-8.285156 0-15 6.714844-15 15v119.996094c0 8.285156 6.714844 15 15 " +
        "15zm15-119.996094h76c3.648438 0 7.171875-1.332031 9.910156-3.742188l15.542969-13.675781c28.246094-24.859375 70.847656-24.859375 99.09375 0 3.96875 2.675781 14.9375 17.417969 36.773437 17.417969h48.679688c8.269531 0 15 6.726562 15 15 0 8.269531-6.730469 14.996094-15 14.996094h-120c-8.285156" +
        " 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15h120c23.585938 0 42.976562-18.242188 44.839844-41.355469l61.398437-40.488281c16.101563-10.605469 36.957031-9.625 51.957031.984375l-191.9375 140.996093c-184.117187-31.632812-173.816406-30.136718-176.257812-30.136718h-76zm0 0",
      y:
        "m391 136.5c0-74.992188-60.5625-136-135-136s-135 61.007812-135 136c0 74.4375 60.5625 135 135 135s135-60.5625 135-135zm-30 0c0 52.804688-39.183594 96.621094-90 103.921875v-27.164063c17.375-6.238281 " +
        "29.839844-22.871093 29.839844-42.363281 0-24.8125-20.1875-45-45-45-8.273438 0-15-6.730469-15-15 0-8.273437 6.726562-15 15-15 8.269531 0 15 6.726563 15 15 0 8.28125 6.714844 15 15 15 8.28125 0 15-6.71875 15-15 " +
        "0-19.496093-12.464844-36.128906-29.839844-42.367187v-36.941406c50.816406 7.371093 90 51.605468 90 104.914062zm-210 0c0-53.308594 39.183594-97.542969 90-104.914062v36.832031c-17.542969 6.148437-30.160156 22.859375-30.160156 42.476562 " +
        "0 24.8125 20.1875 44.996094 45 44.996094 8.269531 0 15 6.730469 15 15 0 8.273437-6.730469 15-15 15-8.273438 0-15-6.726563-15-15 0-8.28125-6.71875-15-15-15-8.285156 0-15 6.71875-15 15 0 19.617187 12.617187 36.328125 30.160156 42.476563v27.054687c-50.816406-7.300781-90-51.117187-90-103.921875zm0 0"
    };
  },
  components: {
    // RecentOrder,
    chart
  },
  methods: {
    getAllRestaurants() {
      let request;
      if (this.restaurant_uuid) {
        let obj = {
          params: {
            uuid: this.restaurant_uuid
          }
        };
        request = axios.get(this.$path + "/admin/restaurant", obj);
      } else {
        request = axios.get(this.$path + "/admin/restaurant");
      }
      request
        .then(({ data }) => {
          this.restaurants = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    dashboardFilter(uuid, selectedRestaurant) {
      // console.log("uuid", uuid, "selectedRestaurant", selectedRestaurant);
      let request;
      if (uuid !== undefined) {
        this.uuid = uuid;
        this.selectedRestaurant = selectedRestaurant;
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(this.$path + "/admin/dashboard/admin", obj);
      } else {
        request = axios.get(this.$path + "/admin/dashboard/admin");
      }

      this.loader = true;

      request
        .then(({ data }) => {
          // console.log("data", data.data);
          this.data.analytics.total_sale_today_count =
            data.data.analytics.total_sale_today_count;
          this.data.analytics.total_sale_today_amount =
            data.data.analytics.total_sale_today_amount;
          this.data.analytics.total_sale_week_count =
            data.data.analytics.total_sale_week_count;
          this.data.analytics.total_sale_week_amount =
            data.data.analytics.total_sale_week_amount;
          this.data.analytics.total_sale_month_count =
            data.data.analytics.total_sale_month_count;
          this.data.analytics.total_sale_month_amount =
            data.data.analytics.total_sale_month_amount;
          this.data.cards = [...data.data.cards];
          this.data.revenues = [...data.data.revenues];
          this.data.revenue.total_revenue = data.data.revenue.total_revenue;
          this.data.users = [...data.data.users];
          // this.data = data.data;
          this.allCustomers();
          this.loyalCustomers(uuid);
          this.mostUsedItems(uuid);
          this.mostFavItems(uuid);
          this.getSales(uuid);
          this.BestSellMenus(uuid);
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    loyalCustomers(uuid) {
      let request;
      if (uuid !== undefined) {
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(
          this.$path + "/admin/dashboard/loyal/customers",
          obj
        );
      } else {
        request = axios.get(this.$path + "/admin/dashboard/loyal/customers");
      }
      request
        .then(({ data }) => {
          this.customers = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    allCustomers() {
      // let form = new FormData();
      // form.append("uuid", this.uuid);
      axios
        .get(this.$path + "/admin/user")
        .then(({ data }) => {
          this.all_customers = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    mostUsedItems(uuid) {
      let request;
      if (uuid !== undefined) {
        this.uuid = uuid;
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(
          this.$path + "/admin/dashboard/most/used/items",
          obj
        );
      } else {
        request = axios.get(this.$path + "/admin/dashboard/most/used/items");
      }
      request
        .then(({ data }) => {
          this.usedItems = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    BestSellMenus(uuid) {
      let request;
      if (uuid !== undefined) {
        this.uuid = uuid;
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(
          this.$path + "/admin/dashboard/best/selling/menus",
          obj
        );
      } else {
        request = axios.get(this.$path + "/admin/dashboard/best/selling/menus");
      }
      request
        .then(({ data }) => {
          this.best_sell_menus = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getSales(uuid) {
      let request;
      if (uuid !== undefined) {
        this.uuid = uuid;
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(this.$path + "/admin/dashboard/get/sales", obj);
      } else {
        request = axios.get(this.$path + "/admin/dashboard/get/sales");
      }
      request
        .then(({ data }) => {
          this.sales = data.data;
          // console.log("sales", data.data);
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    mostFavItems(uuid) {
      let request;
      if (uuid !== undefined) {
        this.uuid = uuid;
        let obj = {
          params: {
            uuid: uuid
          }
        };
        request = axios.get(
          this.$path + "/admin/dashboard/favourite/items",
          obj
        );
      } else {
        request = axios.get(this.$path + "/admin/dashboard/favourite/items");
      }
      request
        .then(({ data }) => {
          this.favItems = data.data;
          this.loader = false;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    SearchDropdown() {
      this.ShowDropdownList = !this.ShowDropdownList;
    },

    closeDropdown() {
      this.ShowDropdownList = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeDropdown();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(
      localStorage.getItem("currentUser")
    ).restaurant;
    if (this.restaurantData) {
      this.restaurant_uuid = this.restaurantData.uuid;
    }
    this.getAllRestaurants();
    this.dashboardFilter();
  }
};
</script>

<style scoped>
#filter {
  max-width: 200px;
}
.card.card-custom {
  background-color: #fbfbfb !important;
  border-radius: 0;
  box-shadow: none;
}
.card.card-custom > .card-header,
.card-header {
  border: none !important;
}
.progressbar {
  margin-left: 10px;
  margin-top: 10px;
}
.address-item {
  min-height: 165px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 20px;
  margin-bottom: 15px;
}
.address-icon {
  padding: 15px 0px;
  background: linear-gradient(180deg, #624fd1 0%, #78aeff 100%);
  border-radius: 20px 20px 0 0;
}

.address-icon span {
  margin-left: 10px;
  font-size: 20px;
  color: white;
}

.address-icon i {
  font-size: 38px;
  color: white;
}

.address-item h3 {
  margin-top: 8px;
  font-size: 28px;
  font-weight: 600;
  color: #f79925;
}

.address-item a {
  text-decoration: none;
  color: #9b9b9b;
}

.progressbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressbar h3 {
  color: #202020;
}

.progressbar p {
  color: #9b9b9b;
}

.progressbar-icon.address-icon svg {
  fill: #fff;
  width: 42px;
  height: 42px;
}
</style>
